import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import WidgetCard from "./Cards/WidgetCard";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const StaticCalWeekPicker = (props) => {
  const [pickDate, setPickDate] = useState(new Date());
  console.log("minDate", props.minDate, "----- maxDate", props.maxDate);

  useEffect(() => {
    if (props.onDateChange) {
      props.onDateChange({
        selectedDate: pickDate,
        startOfWeek: startOfWeek(pickDate),
        endOfWeek: endOfWeek(pickDate),
      });
    }
  }, [pickDate]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!pickDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(pickDate);
    const end = endOfWeek(pickDate);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <WidgetCard title={"Calendar"}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayWeekNumber
          displayStaticWrapperAs="desktop"
          label="Week picker"
          value={pickDate}
          onChange={(newValue) => {
            setPickDate(newValue);
          }}
          renderDay={renderWeekPickerDay}
          inputFormat="'Week of' MMM d"
          minDate={props.minDate}
          maxDate={props.maxDate}
        />
      </LocalizationProvider>
    </WidgetCard>
  );
};

export default StaticCalWeekPicker;
