import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/* Routing */
import { useNavigate } from "react-router-dom";

/* UI */
import { Button, Card, Stack, Typography, CardCover, Divider } from "@mui/joy";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import ProductCardHeader from "./ProductCardHeader";
import { motion, useAnimation } from "framer-motion";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { makeStyles } from "@mui/styles";
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";
import Crop54RoundedIcon from "@mui/icons-material/Crop54Rounded";

/* own modules */
import * as PRICING from "./../../constants/Pricing";
import { cartActions } from "../../store/cart-slice";
import {
  notifyActions,
  NOTIFICATION_TYPE,
} from "../../store/notification-slice";
import { registerFreeProgram } from "./../../store/firebaseActions";

/* stylig */
const useStyles = makeStyles((theme) => ({
  coverArt: {
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: 5,
  },
}));

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgb(230, 200, 50)",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

/* ----------- Animations */
const coverArtAnimationVariants = {
  loaded: { opacity: 0.95, scale: 1 },
};

/* ================ Product Card] ================ */
const ProductCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const animationControl = useAnimation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  /* check the size of the card */
  const cardWidth = props.cardWidth || 350;
  const cardHeight = props.cardHeight || 300;

  console.log("ProductCard -> props", props.data);

  /* ------------[Handlers] -------------- */
  const cardClickedHandler = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.previewOnly || props.disableOnClick) {
      return;
    } else {
      navigate(`/dashboard/workout-programme/${props.UID}`);
    }
  };

  const handleAddToCart = () => {
    if (!user.isLoggedIn) {
      navigate("/login", {
        state: { from: window.location.pathname },
      });
      return;
    }

    dispatch(
      cartActions.addToCart({
        title: props.data?.name,
        programUID: props.UID,
        coachName: props.data?.ownerName,
        coachUID: props.data?.ownerUID,
        coverArt: props.data?.coverArtURL,
        pricing: props.data?.pricing,
        priceID: props.data?.priceID,
        stripeAccountID: props.data?.stripeAccountID || null,
        isLemonadeProduct: props.data?.isLemonadeProduct || false,
      })
    );
  };

  const handleAddToLibrary = () => {
    setLoading(true);
    if (!user.isLoggedIn) {
      navigate("/login");
      setLoading(false);
      return;
    }

    registerFreeProgram(user.user.uid, props.UID, props.data?.name)
      .then(() => {
        const notification = {
          severity: NOTIFICATION_TYPE.success,
          title: "Awesome!",
          message: `Programming "${props.data?.name}" added to your library.`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .catch((err) => {
        const notification = {
          severity: NOTIFICATION_TYPE.error,
          title: "Oooobs!",
          message: `Something went wrong, try again!`,
        };
        dispatch(notifyActions.sendNotification(notification));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* ----- Pricing logic ----- */
  let pricingLabel = "";
  if (props.data?.pricing) {
    switch (props.data.pricing?.type) {
      case PRICING.TYPE.free:
        pricingLabel = "FREE";
        break;

      case PRICING.TYPE.oneTime:
        pricingLabel = `${props.data.pricing.amount} €`;
        break;

      case PRICING.TYPE.subscription:
        pricingLabel = `${props.data.pricing.amount} €/Month `;
        break;

      default:
        pricingLabel = "No Price Set";
        break;
    }
  }

  /* ----- Animations ----- */
  const imageLoaded = () => {
    animationControl.start("loaded");
  };

  /* ====================[UI Return]===================== */
  return (
    <motion.div onClick={cardClickedHandler}>
      <Card
        variant="plain"
        sx={{
          //boxShadow: "sm",
          width: cardWidth,
          height: cardHeight,
          backgroundColor: "transparent",
          "&:hover": {
            boxShadow: "lg",
          },
        }}
      >
        <ProductCardHeader
          owner={props.data?.ownerName}
          AvatarPicUID={props.data?.ownerUID}
          avatar={props.avatar}
          productName={props.data?.name}
        />
        <Stack spacing={1} direction={"column"} sx={{ flex: "1 1 auto" }}>
          <Card variant="plain" sx={{ flex: "1 1 auto" }}>
            <CardCover>
              {props.data?.coverArtURL && (
                <motion.img
                  initial={{ opacity: 0 }}
                  src={props.data?.coverArtURL}
                  transition={{ duration: 0.6 }}
                  animate={animationControl}
                  variants={coverArtAnimationVariants}
                  onLoad={imageLoaded}
                  className={classes.coverArt}
                />
              )}
              {!props.data?.coverArtURL && (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Click here to upload photo!
                </Typography>
              )}
              {props.data?.effortLevel && (
                <Stack
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: 1,
                  }}
                >
                  <StyledRating
                    name="EffortLevel"
                    value={props.data?.effortLevel}
                    readOnly={true}
                    precision={0.5}
                    icon={<RectangleRoundedIcon fontSize="inherit" />}
                    emptyIcon={<Crop54RoundedIcon fontSize="inherit" />}
                  />
                </Stack>
              )}
              {!props.previewOnly && !props.disableAddToCart && (
                <Stack
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    padding: 1,
                  }}
                >
                  <Button
                    size="sm"
                    loading={loading}
                    onClick={
                      props.data?.pricing?.type === PRICING.TYPE.free
                        ? handleAddToLibrary
                        : handleAddToCart
                    }
                    endDecorator={
                      !(props.pricing?.type === PRICING.TYPE.free) && (
                        <AddShoppingCartIcon />
                      )
                    }
                  >
                    {pricingLabel}
                  </Button>
                </Stack>
              )}
            </CardCover>
          </Card>
        </Stack>
      </Card>
    </motion.div>
  );
};

export default ProductCard;
