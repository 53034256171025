import React, { useContext } from "react";
import { useSelector } from "react-redux";
/* Material UI */
import { AppBar, Toolbar } from "@mui/material";
import { Button, Grid, Divider, Typography, Stack } from "@mui/joy";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CartBadgeButton from "../components/Cart/CartBadgeButton";
import UserAvatarAndPopoverMenu from "./UserAvatarAndPopoverMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
/* Authentication imports */
import AuthContext from "../store/auth-context";
/* Routing */
import { useNavigate, useLocation } from "react-router-dom";

/* Header Component */
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isUserLoggedIn = useSelector((state) => state.user.isLoggedIn);

  /* button and link Handlers */
  const goToPage = (pageName) => {
    navigate(pageName, { state: { from: location.pathname } });
  };

  const onClickOnSignup = () => {
    navigate("/register", { state: { from: location.pathname } });
  };

  /* --- App Bar config ----- */
  let appBarColor = "default";
  let appBarPosition = "fixed";
  let appBarElevation = 2;

  let isHomePage = location.pathname === "/";
  if (isHomePage || location.pathname === "/payment-status") {
    appBarColor = "transparent";
    appBarPosition = "absolute";
    appBarElevation = 0;
  }

  /* sign out */
  const signOutTheUser = async () => {
    await authCtx.userLogOut();
    goToPage("/");
  };

  return (
    <AppBar
      position={appBarPosition}
      color={appBarColor}
      elevation={appBarElevation}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      item="true"
    >
      <Toolbar>
        <Grid container direction="row" spacing={1} xs={12}>
          <Grid xs alignSelf={"center"}>
            {!isHomePage && (
              <Typography
                onClick={() => goToPage("/")}
                sx={{
                  cursor: "pointer",
                  fontFamily: "Oswald",
                  color: "black",
                  fontSize: smallScreen ? 18 : 20,
                }}
              >
                LEMONADE TRAINING
              </Typography>
            )}
          </Grid>
          <Grid justifyContent={"flex-end"}>
            <Stack direction={"row"} spacing={2}>
              {isUserLoggedIn && <CartBadgeButton />}
              {isUserLoggedIn && (
                <Divider sx={{ color: "white" }} orientation="vertical" />
              )}
              {!isUserLoggedIn && (
                <Stack direction={"row"} spacing={2}>
                  <Button
                    color="primary"
                    onClick={onClickOnSignup}
                    size={smallScreen ? "sm" : "md"}
                  >
                    Sign Up
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => goToPage("/login")}
                    endDecorator={<ChevronRightIcon />}
                    size={smallScreen ? "sm" : "md"}
                  >
                    Sign In
                  </Button>
                </Stack>
              )}
              {isUserLoggedIn && (
                <UserAvatarAndPopoverMenu onLogout={signOutTheUser} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
