import React, { useState } from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

// -- own modules
import CoverArtPickAndPreview from "../ProgramCard/CoverArtPickAndPreview";
import ProgramControlPanel from "./ProgramControlPanel";

/* stylig */
const useStyles = makeStyles((theme) => ({
  topDiv: {
    display: "flex",
    flexDirection: "row",
  },
}));

/*
 *
 */
function ProgramInfo(props) {
  const classes = useStyles();
  const [description, setDescription] = useState(
    props.programData.description || ""
  );

  const descriptionChangeHandler = (e) => {
    setDescription(e.target.value);
  };

  const coverArtChanged = async (file) => {
    //const coverURL = await uploadCoverArt(file, props.programData.CoachUID);
  };

  return (
    <>
      <div className={classes.topDiv}>
        <TextField
          name="ProgrammingDescription"
          fullWidth
          multiline
          id="ProgrammingDescription"
          label="What this programming about"
          value={description}
          onChange={descriptionChangeHandler}
          InputProps={{ readOnly: true }}
          rows={8}
          sx={{ mr: 4 }}
        />
        <CoverArtPickAndPreview
          onCoverArtChanged={coverArtChanged}
          title={props.programData.name}
          coverArtURL={props.programData.coverArtURL}
          coachName={props.programData.CoachName}
          coachUID={props.programData.CoachUID}
          pricing={props.programData.pricing}
          editable={false}
        />
      </div>
      {props.isItMyProgramme && (
        <ProgramControlPanel
          isLoading={props.isLoading}
          programUID={props.programUID}
          programData={props.programData}
        />
      )}
    </>
  );
}

export default ProgramInfo;
